import React from "react";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import logo from "./bee-hive-yellow-highlight.svg";
import "./App.css";
import { createBrowserHistory } from "history";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Routes from "./Routes";
import { createMuiTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#1f2329",
      paper: "#282c34"
    }
  }
});
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: 'auto',
    height: "92vh",
  },
  container: {
    paddingTop: 20,
  },
  logoText: {
    paddingLeft: 15,
  },
  footer: {
    padding: theme.spacing(1),
    marginTop: "auto",
    position: "sticky",
    top:0,
    backgroundColor: '#282c34',
    boxShadow: '5px 10px 8px 12px black'
  },
  footerLogo: {
    height: "4vmin",
    position: 'relative',
    transition: 'top 2s',
  }
}));
const browserHistory = createBrowserHistory();
function App() {
  const [isLoading, setLoading] = React.useState(true);
  const [footerTop, setFooterTop] = React.useState('-50vh')
  const [footerLeft, setFooterLeft] = React.useState('48vw')
  const [logoTextVisible, setLogoTextVisible] = React.useState(false)
  const classes = useStyles();
  const footerAnchor = React.useRef(null);
  const imgAnchor = React.useRef(null);
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setFooterTop('0')
      setTimeout(()=>{
        setFooterLeft('0')
        setTimeout(()=>{
          setLogoTextVisible(true)
        }, 750)
      }, 750)
    }, 750);
  }, [setLoading]);

  React.useEffect(()=>{
    ReactGA.initialize('UA-116598472-3');
    ReactGA.pageview(window.location.pathname + window.location.search);
    browserHistory.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname)
      }
    );
  },[])
  return (
    <div className="App">
      {isLoading ? (
        <header className="App-header">
          <div ref={imgAnchor} />
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      ) : (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <Container className={classes.container} maxWidth="md">
              <Router history={browserHistory}>
                <Routes />
              </Router>
            </Container>
          </div>
          <footer className={classes.footer}>
              <div ref={footerAnchor} />
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{cursor: 'pointer'}}
              >
                <Grid container direction="row" item xs={6} alignItems="center"
                  onClick={() => {
                    browserHistory.push("/")
                    window.scrollTo(0, 0)
                  }}
                >
                  <Grid item>
                    <img className={classes.footerLogo}
                      style={{
                        animation: 'App-logo-spin 3s linear',
                        transition: 'top ease .5s, left ease .5s',
                        top: footerTop,
                        left: footerLeft,
                      }} src={logo} alt="logo" />
                    <Divider />
                  </Grid>
                  <Grid className={classes.logoText}
                     item>
                     <Grow in={logoTextVisible}>
                       <Typography variant="h6">hivemind.monster</Typography>
                     </Grow>
                  </Grid>
                </Grid>
                <Grid item>
                  <Slide in={true} direction="right">
                    <Grid container direction="row" alignItems="center" spacing={1}>
                      <Grid item>
                        <FacebookShareButton url={window.location.toString()}>
                          <FacebookIcon size={16} round={false} />
                        </FacebookShareButton>
                      </Grid>
                      <Grid item>
                        <TwitterShareButton url={window.location.toString()}>
                          <TwitterIcon size={16} />
                        </TwitterShareButton>
                      </Grid>
                      <Grid item>
                        <RedditShareButton url={window.location.toString()}>
                          <RedditIcon size={16} />
                        </RedditShareButton>
                      </Grid>
                      <Grid item>
                        <LinkedinShareButton url={window.location.toString()}>
                          <LinkedinIcon size={16} />
                        </LinkedinShareButton>
                      </Grid>
                    </Grid>
                  </Slide>
                </Grid>
              </Grid>
          </footer>
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
