export default [
  // {
  //   img: "eth-erc-20.png",
  //   title: "Creating an Eth Token",
  //   created: "04/25/2022",
  //   author: "owner",
  //   slug: "create-eth-token",
  //   tags: ["Crypto-Currency"],
  //   text: ``
  // },
  {
    img: "HuionH610ProV2-1.jpg",
    title: "Huion H610 Pro V2 Review",
    created: "08/9/2021",
    author: "owner",
    slug: "huion-h610-pro-v2-review",
    tags: ["Product Review"],
    text: ``
  },
  {
    img: "GodotScreenshot1.png",
    title: "Getting Going With Godot",
    created: "08/9/2021",
    author: "owner",
    slug: "getting-going-with-godot",
    tags: ["Godot"],
    text: ``
  },
  {
    img: "passive-income-banner.svg",
    title: "Passive income for Software Engineers",
    created: "08/1/2021",
    author: "owner",
    slug: "passive-income-software-engineer",
    tags: [],
    text: ``
  },
  {
    img: "github-actions.png",
    title: "React Deployment Github Actions",
    created: "07/30/2021",
    author: "owner",
    slug: "react-deployment-github-actions",
    tags: ["React", "NodeJS", "NGINX"],
    text: ``
  },
  {
    img: "san-jose-costa-rica.jpg",
    title: "Travel Guide to Costa Rica 2021",
    created: "07/27/2021",
    author: "owner",
    slug: "travel-guide-costa-rica-2021",
    tags: ['Travel'],
    text: ``
  },
  {
    img: "ceaserCipher.png",
    title: "Rotational Cipher",
    created: "06/15/2021",
    author: "owner",
    slug: "rotational-cipher",
    tags: [],
    text: ``
  },
  {
    img: "Coinbase-1.jpg",
    title: "Coinbasing like a Pro",
    created: "04/2/2021",
    author: "owner",
    slug: "coinbasing-like-a-pro",
    tags: ["Crypto-Currency"],
    text: ``
  },
  {
    img: "miningeth.jpg",
    title: "Mining Ethereum (ETH) in 2021",
    created: "03/31/2021",
    author: "owner",
    slug: "mining-eth-2021",
    tags: ["Crypto-Currency"],
    text: ``
  },
  {
    img: "cryptocurrency.jpg",
    title: "Crypto-Currency 101",
    created: "03/24/2021",
    author: "owner",
    slug: "Crypto-Currency-101",
    tags: ["Crypto-Currency"],
    text: ``
  },
  {
    img: "morphfinal.gif",
    title: "SVG Animation: SMIL",
    created: "03/16/2020",
    author: "owner",
    slug: "svg-animation-smil",
    tags: ["SVG"],
    text: ``
  },
  {
    img: "monster-eye-closeup.gif",
    title: "Create Animated Monster Eye SVG",
    created: "03/13/2020",
    author: "owner",
    slug: "create-monster-eye-svg",
    tags: ["SVG"],
    text: ``
  },
  {
    img: "svg-logo-text-550x526.png",
    title: "SVG Animation!",
    author: "owner",
    created: "03/10/2020",
    slug: "svg-animation",
    tags: ["SVG", "React", "Animation"],
    text: ``,
  },
  {
    img: "dockerandpostgres.svg",
    title: "Docker and Postgres.. this should be easy!",
    created: "02/24/2020",
    author: "owner",
    slug: "docker-and-postgres",
    tags: ["Docker", "Postgres"],
    text: ``,
  },
  {
    img: "full-stack-app.svg",
    title: "Full stack app.. Seriosly!",
    created: "02/17/2020",
    author: "owner",
    slug: "full-stack-app",
    tags: ["NodeJS", "NGINX", "ExpressJS", "Postgres", "React"],
    text: ``
  },
];
