import React from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import {Search as SearchIcon} from '@material-ui/icons';
export default function (props) {
  return (
    <TextField
      style={{
        margin: '15px'
      }}
      fullWidth
      onChange={(e)=>props.onChange(e.target.value)}
      label="Search posts"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  )
}
