import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { BackButton, ExternalLink } from "../../../components/common";
import Header from "./Header"
import Paragraph from "./Paragraph"

export default function(props) {
  return (
    <Container maxWidth="md">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={"Creating an Ethereum Based Token (ERC-20)"} created="04/25/2022" />
            <Paragraph>
            {`
              Before we get into the nitty gritty here... let's do a little bit of homework.
              You can start by looking at the documentation for ERC-20 tokens
            `}
            <ExternalLink href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/">
              here.
            </ExternalLink>
            {`
              Then the actual EIP is
            `}
            <ExternalLink href="https://eips.ethereum.org/EIPS/eip-20">
              here.
            </ExternalLink>
            {`
              That is a lot to unpack!  Most of these (with good reason) are very
              general terms and the API is very open.  Today we are going to focus
              on the general idea of the token, and later we can talk about ideas
              on how to actually implement them.  To get started we are going to need to do the following
            `}
            </Paragraph>
            <ul>
              <li>Get a wallet</li>
              <li>Deploy our contract</li>
            </ul>
            <Typography variant="h4">Getting a wallet</Typography>
            <Paragraph>
            {`
              As our wallet we will be using 0xba2dE839597950D1f77a9A32A3E79D21AD739bB5 on the ropsten test network.
              I created my wallet using
            `}
            <ExternalLink href="https://metamask.io/">
              Metamask
            </ExternalLink>
            {`.  Metamask is based on the specification in
            `}
            <ExternalLink href="https://eips.ethereum.org/EIPS/eip-1193">
              EIP-1193
            </ExternalLink>
            {`.`}
            </Paragraph>
            <Typography variant="h4">Deploy our contract</Typography>
            <Paragraph>
            {`
              In order to actually deploy our contract to the ropsten network we will use
              a web based IDE called
            `}
            <ExternalLink href="http://remix.ethereum.org/">
              Remix
            </ExternalLink>
            {`
              that will hook into metamask and deploy our contract using an Injected
              Web3 Provider.
            `}
            </Paragraph>
            <Typography variant="h4">Example functionalities ERC-20 provides:</Typography>
            <ul>
              <li>transfer tokens from one account to another</li>
              <li>get the current token balance of an account</li>
              <li>get the total supply of the token available on the network</li>
              <li>approve whether an amount of token from an account can be spent by a third-party account</li>
            </ul>
            <Typography variant="h4">References</Typography>
            <ul>
              <li>
                <ExternalLink href="https://eips.ethereum.org/EIPS/eip-20">
                  Ethereum Improvement Proposal for EIP-20
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/">
                  ERC-20 Documentation
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://blog.paulmcaviney.ca/deploy-to-ropsten-testnet">
                  Deploying to ropsten using remix
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://docs.openzeppelin.com/">
                  Open Zeppelin Docs
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://eips.ethereum.org/EIPS/eip-1193">
                  Ethereum JavaScript provider API (EIP-1193)
                </ExternalLink>
              </li>
            </ul>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
