import React from "react"
import SvgIcon from '@material-ui/core/SvgIcon';

export default function () {
  const [eyelidHeight, setEyelidHeight] = React.useState(1.0);
  const blink = ()=>{
    setEyelidHeight(0)
    setTimeout(()=>setEyelidHeight(1.0), 1250)
  }
  React.useEffect(()=>{
    blink()
  }, [setEyelidHeight])
  return (
    <SvgIcon onClick={blink} style={{
      transform: 'scale(3.0)',
      margin: '15px',
      transition: 'transform ease 1s'
    }}>
      <svg id="SVGRoot" width="16px" height="16px" version="1.1"
         viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
         >
       <g>
        <circle cx="8" cy="8" r="8" fill="#fff" strokeWidth="3.75"/>
        <ellipse cx="8.0889" cy="8.0188" rx=".5" ry="7.9357" fill="#a00"
        strokeWidth="4.5399"/>
        <path
          style={{
            transform: `scaleY(${eyelidHeight})`,
            transition: 'transform ease 2s',
            transformOrigin: 'bottom'
          }}
          d="m16 8a8 8 0 0 1-7.9928 8 8 8 0 0 1-8.0072-7.9856"
          fill="#002900" strokeWidth="3.7795"/>
        {/*
        */}
        <path
        style={{
          transform: `scaleY(${eyelidHeight}) rotate(180deg)`,
          transition: 'transform ease 2s',
          transformOrigin: 'top',
        }}
          transform="scale(1,-1)"
          d="m16-8.0144a8 8 0 0 1-7.9928 8 8 8 0 0 1-8.0072-7.9856"
          fill="#002900" strokeWidth="3.7795"/>
       </g>
      </svg>
    </SvgIcon>
  )
}
