import React from "react";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import HiveBrain from "../../../../components/Icons/HiveBrain";
import HiveBrainExternal from "../../../../components/Icons/HiveBrainExternal";
import HiveBrainInternal from "../../../../components/Icons/HiveBrainInternal";
import HiveBrainKute from "../../../../components/Icons/HiveBrainKute";
import Gist from "react-gist"
import Header from "../Header"
import Paragraph from "../Paragraph"

const SMIL_ABSTRACT = "https://www.w3.org/TR/REC-smil/";
const SMIL_ANIMATION_HREF = "https://www.w3.org/TR/REC-smil/smil-animation.html";
const MOZILLA_SMIL = "https://developer.mozilla.org/en-US/docs/Web/SVG/SVG_animation_with_SMIL";
const KUTE_HREF = "https://thednp.github.io/kute.js/index.html";
const KUTE_TWEEN_OPTS_HREF = "https://thednp.github.io/kute.js/options.html";
const POLYGON_HREF = "https://developer.mozilla.org/en-US/docs/Web/SVG/Element/polygon";
export default function(props) {
  const [showKuteSource, setShowKuteSource] = React.useState(false);
  const [showSMILSource, setShowSMILSource] = React.useState(false);
  const title = "SVG Animation: SMIL";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Container maxWidth="sm">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="03/16/2020" />
            <Paragraph>
            {`
              Today we are going to look at SVG Morphing using
            `}
            <ExternalLink href={SMIL_ABSTRACT}>SMIL</ExternalLink>.
            {`
              We will be going taking these existing two SVG and morphing them
              from one to the other.
            `}
            </Paragraph>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Tooltip title="If only...">
                  <img src="/icons/brain.svg" alt="brain svg" width="50px" />
                </Tooltip>
              </Grid>
              <Grid item>
                <ArrowForwardIcon />
              </Grid>
              <Grid item>
                <Tooltip title="... I had a Hive!">
                  <img src="/bee-hive.svg" alt="hive svg" width="50px" />
                </Tooltip>
              </Grid>
            </Grid>
            <Paragraph>
              {`
                First thing we will need to do is copy both of the paths from each
                of these files into one.  Then we will need to add an animate
                tag.  If you try to just animate the paths of these above SVG, the image
                will change, however, it won't be animated.  We can only animate
                a polygon with the same amount of points.  Since the brain svg
                is way more complex than our hive, we are going to need to change
                up a few things.  We will break out our SVG into two separate
                animations.  First the outer layer:
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <HiveBrainExternal />
              </Grid>
            </Grid>
            <Paragraph>
              {`
                Not really that exciting.  I ended up creating the Polygons by
                hand using points defined in Inkscape.
              `}
            </Paragraph>
            <Paragraph>
              {`
                Next lets focus on the internal portion of the SVG.  I ended up
                tracing the SVG with minimal points.  I ended up having 30 nodes
                in the first iteration.  Here is the result
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <HiveBrainInternal />
              </Grid>
            </Grid>
            <Paragraph>
              {`
                Now this is where things started to go off of the rails.  I could
                not get this complex polygon to animate properly.  After debugging
                my code I realized I should try Chrome, low and behold it is
                is properly working.
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <img src="/blog/morph.gif" alt="morph"/>
              </Grid>
            </Grid>
            <Paragraph>
              {`
                Great!  now when we put the two together we get a fairly complete
                morphing!
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <HiveBrain />
              </Grid>
            </Grid>
            <Paragraph>
              {`
                And there we have it!  We certainly could use a bit more detail
                on the brain stem.  Particularly another fold near the bottom.
                Here is an animated GIF of how it should work.  Firefox may
                have issues rendering it.
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <img src="/blog/morphfinal.gif" alt="morph final"/>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button onClick={()=>{setShowSMILSource(!showSMILSource)}}>
                  {!showSMILSource?'Show':'Hide'} source
                </Button>
              </Grid>
            </Grid>
            <Collapse in={showSMILSource}>
              <Gist id="8100b95503196495dddf1c3d72b1e273" />
            </Collapse>
            <Paragraph>
              {`
                So I'd say the most important lesson to take away here is that it
                matters how complex of a shape you want to morph.  Reasonably
                simple shapes will work out of the box, but you may find issue
                with complex polygons cross browser.  You may want to check out
                libraries for this like
              `}
              <ExternalLink href={KUTE_HREF}>KUTE</ExternalLink>
              {`
                Here is the SVG Moprh using this library:
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <HiveBrainKute />
              </Grid>
            </Grid>
            <Paragraph>
              {`
                Now we are getting somewhere!  And it is working in firefox out
                of the box.  However, we still have this black fill when doing the
                morphing.  I have been scouring the
              `}
              <ExternalLink href={KUTE_TWEEN_OPTS_HREF}>docs</ExternalLink>
              {`, but have come up empty
                handed so far.  Here is the source code for using KUTE with React.
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <Button onClick={()=>{setShowKuteSource(!showKuteSource)}}>
                  {!showKuteSource?'Show':'Hide'} source
                </Button>
              </Grid>
            </Grid>
            <Collapse in={showKuteSource}>
              <Gist id="62d1fa978f00326449869e7e2a09371b" />
            </Collapse>
            <Paragraph>
              {`
                In conclusion, you might want to think about using a library when
                doing your SVG animations.  If the shape is simple enough though,
                it might make sense to just roll your own solution.  You be the
                judge!
              `}
            </Paragraph>
            <Typography variant="h5">References</Typography>
            <Divider />
            <div>
              - <ExternalLink href="https://css-tricks.com/svg-shape-morphing-works/">
                SVG Shape Morphing Works
                </ExternalLink>
            </div>
            <div>
              - <ExternalLink href={KUTE_HREF}>
                KuteJS
                </ExternalLink>
            </div>
            <div>
              - <ExternalLink href={SMIL_ANIMATION_HREF}>
                SMIL Animation
                </ExternalLink>
            </div>
            <div>
              - <ExternalLink href={POLYGON_HREF}>
                SVG Polygon
                </ExternalLink>
            </div>
            <div>
              - <ExternalLink href={MOZILLA_SMIL}>
                SVG Animation with SMIL
                </ExternalLink>
            </div>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
