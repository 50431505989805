import React from "react";

export default function () {
  const [backfills, setBackfills] = React.useState([...new Array(7)].map(()=>false))
  React.useEffect(()=>{
    const fill = ()=>{
      const seed = Math.round(Math.random() * 7);
      setBackfills(backfills=>backfills.map(((backfill,idx)=>seed===idx?!backfill:backfill)))
      setTimeout(fill, 1500)
    }
    fill();
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  },[setBackfills]);
  return (
    <svg
     style={{
       animation: 'App-logo-spin infinite 20s linear'
     }}
    width="130px" height="130px" version="1.1" viewBox="0 0 136 132"
    xmlns="http://www.w3.org/2000/svg">
     <g>
        <path
          transform="matrix(.026846 .8558 -.83262 .026119 93.678 -21.749)"
          d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
          fill="#ff0000"
          fillOpacity={backfills[0] === true? 1.0: 0.0}
          style={{
            transition: 'fill-opacity ease 2s',
            display: 'inline'
          }}
        />
      <path
        fillOpacity={backfills[1] === true? 1.0: 0.0}
        style={{
          transition: 'fill-opacity ease 2s',
          display: 'inline'
        }}
        transform="matrix(.044942 .83177 -.83189 .042535 70.447 16.025)"
        d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
        display="inline" fill="#008080"/>
      <path
      fillOpacity={backfills[2] === true? 1.0: 0.0}
      style={{
        transition: 'fill-opacity ease 2s',
        display: 'inline'
      }}
      transform="matrix(.043188 .84637 -.79942 .043281 112.34 15.663)"
      d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
      display="inline" fill="#ffcc00"/>
      <path
        fillOpacity={backfills[3] === true? 1.0: 0.0}
        style={{
          transition: 'fill-opacity ease 2s',
          display: 'inline'
        }}
       transform="matrix(.026846 .8558 -.83262 .026119 93.678 53.922)"
       d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
       display="inline" fill="#a05a2c"/>
      <path
        fillOpacity={backfills[4] === true? 1.0: 0.0}
        style={{
          transition: 'fill-opacity ease 2s',
          display: 'inline'
        }}
        transform="matrix(.025897 .8379 -.80319 .025573 135.09 55.012)"
        d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
       display="inline" fill="#37c837"/>
      <path
        fillOpacity={backfills[5] === true? 1.0: 0.0}
        style={{
          transition: 'fill-opacity ease 2s',
          display: 'inline'
        }}
        transform="matrix(.038217 .80127 -.769 .037693 134.15 -20.536)"
        d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
       display="inline" fill="#ff6600"/>
      <path
        fillOpacity={backfills[6] === true? 1.0: 0.0}
        style={{
          transition: 'fill-opacity ease 2s',
          display: 'inline'
        }}
       transform="matrix(.044942 .85504 -.83189 .043725 158.46 14.868)"
       d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
      display="inline" fill="#800080"/>
     </g>
     <g>
      <g transform="matrix(.26458 0 0 .26458 .32485 -1.9648)">
       <polygon points="156.25 218.85 113.16 193.97 105.66 206.96 141.25 227.51 141.25 244.68 156.25 244.68"/>
       <rect x="141.25" y="251.8" width="15" height="15.913"/>
       <polygon points="321.91 218.85 278.82 193.97 271.32 206.96 306.91 227.51 306.91 244.68 321.91 244.68"/>
       <rect x="306.91" y="251.8" width="15" height="15.913"/>
       <path d="m429.17 156v-95.635l-90.334-52.155-82.833 47.824-82.833-47.824-90.334 52.155v95.635l-82.833 47.824v104.31l82.833 47.824v95.67l90.333 52.154 82.834-47.824 82.833 47.824 90.333-52.154v-95.67l82.834-47.824v-104.31zm-165.67-86.975 75.333-43.494 75.334 43.494v86.975l-75.333 43.494-75.334-43.494zm-165.67 0 75.333-43.494 75.333 43.494v86.975l-75.333 43.494-75.333-43.494zm-82.834 230.45v-86.988l75.323-43.488 75.344 43.5v86.976l-75.334 43.493zm233.5 143.49-75.333 43.494-75.333-43.494v-86.987l75.333-43.494 75.333 43.494zm-67.832-143.49v-86.976l75.333-43.493 75.333 43.494v86.976l-75.333 43.493zm158.17 186.99-75.334-43.494v-86.987l75.333-43.494 75.333 43.494v86.987h1e-3zm158.17-186.99-75.333 43.494-75.333-43.494v-86.976l75.344-43.5 75.322 43.488z"/>
       <polygon points="487.58 218.85 444.49 193.97 436.99 206.96 472.58 227.51 472.58 244.68 487.58 244.68"/>
       <rect x="472.58" y="251.8" width="15" height="15.913"/>
       <polygon points="239.08 75.389 195.99 50.51 188.49 63.5 224.08 84.049 224.08 101.22 239.08 101.22"/>
       <rect x="224.08" y="108.34" width="15" height="15.913"/>
       <polygon points="404.75 75.389 361.66 50.51 354.16 63.5 389.75 84.049 389.75 101.22 404.75 101.22"/>
       <rect x="389.75" y="108.34" width="15" height="15.913"/>
       <polygon points="239.08 362.34 195.99 337.46 188.49 350.45 224.08 371 224.08 388.18 239.08 388.18"/>
       <rect x="224.08" y="395.29" width="15" height="15.913"/>
       <polygon points="404.75 362.34 361.66 337.46 354.16 350.45 389.75 371 389.75 388.18 404.75 388.18"/>
       <rect x="389.75" y="395.29" width="15" height="15.913"/>
      </g>
     </g>
     <g>
     <g fillOpacity="0" fill="#000000">
      <path
        onClick={()=>{
          setBackfills(backfills.map((b,idx)=>idx===0?!b: b))
        }}
        transform="matrix(.059309 .85416 -.83103 .057702 91.755 -24.047)"
        d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
        fill="#000000"/>
      <path
        onClick={()=>{
          setBackfills(backfills.map((b,idx)=>idx===5?!b: b))
        }}
        transform="matrix(.040891 .85524 -.83208 .039784 136.78 -22.523)"
         d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
      />
      <path
        onClick={()=>{
          setBackfills(backfills.map((b,idx)=>idx===1?!b: b))
        }}
       transform="matrix(.059309 .85416 -.83103 .057702 69.53 14.583)"
       d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
       fill="#000000"/>
       <path
         onClick={()=>{
           setBackfills(backfills.map((b,idx)=>idx===2?!b: b))
         }}
        transform="matrix(.059309 .85416 -.83103 .057702 114.51 14.583)"
        d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"
        fill="#ff0000"/>
       <path
         onClick={()=>{
           setBackfills(backfills.map((b,idx)=>idx===3?!b: b))
         }}
         transform="matrix(.059309 .85416 -.83103 .057702 91.755 52.153)"
         d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"/>
       <path
       onClick={()=>{
         setBackfills(backfills.map((b,idx)=>idx===4?!b: b))
       }}
        transform="matrix(.059309 .85416 -.83103 .057702 136.21 52.153)"
        d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"/>
       <path
         onClick={()=>{
           setBackfills(backfills.map((b,idx)=>idx===6?!b: b))
         }}
       transform="matrix(.059309 .85416 -.83103 .057702 158.43 14.583)"
       d="m88.628 60.351-17.398 27.248-32.297-1.4427-14.899-28.691 17.398-27.248 32.297 1.4427z"/>
      </g>
     </g>
    </svg>
  )
}
