import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import Gist from "react-gist"
import Header from "../Header";
import Paragraph from "../Paragraph";
const HREF1 = "https://hub.docker.com/_/postgres";
const HREF2 = "https://docs.docker.com/compose/compose-file/#compose-and-docker-compatibility-matrix";
export default function(props) {
  const title = "Docker and Postgres";
  return (
    <Slide in={true}>
      <Grid container>
        <Grid item>
          <Header title={title} created={"02/24/2020"} />
          <Paragraph>
            In this post we will cover using postgres with Docker, and more
            specifically docker-compose.  The actual Dockerfile is provided
            by docker hub and the docs can be found{" "}
            <ExternalLink href={HREF1}>here</ExternalLink>.  This is an example
            Postgres docker compose yaml file.
          </Paragraph>
          <Gist id="973b3d20f9badd9385d927432d01881c" />
          <Paragraph>
            <Typography variant="body1" paragraph>
              {`
                Lets break this down part by part.  The version refers to the
                compatibility with docker.  You can find the chart
              `}
              <ExternalLink href={HREF2}>here</ExternalLink>
              {`.`}
            </Typography>
            <Typography variant="body1" paragraph>
              {`
                Next is the services that are part of this docker compose setup.
                We are going to define ours called "db" (this could be any name)
                and tell it to use the postgres image that we referenced earlier.
              `}
            </Typography>
            <Typography variant="body1" paragraph>
              {`
                A few interesting notes here is that I chose to use the EXPOSE
                command instead of PORTS.  This means that POSTGRES will only be
                exposed to other macines in the network.
              `}
            </Typography>
            <Typography variant="body1" paragraph>
              {`
                Aside from that!  Just make sure to pick a very secure secret
                and keep it to yourself.  Also not that the volumes are important
                because otherwise your database would not be persisted.
              `}
            </Typography>
          </Paragraph>
          <BackButton />
        </Grid>
      </Grid>
    </Slide>
  );
}
