import React from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import SVGTutorial from "../../../../components/Icons/SVGTutorial";
import SVGTutorial0 from "../../../../components/Icons/SVGTutorial0";
import Gist from "react-gist"
import Header from "../Header"
import Paragraph from "../Paragraph"

const SVG_ANIMATION_WITH_SMIL_URL = "https://developer.mozilla.org/en-US/docs/Web/SVG/SVG_animation_with_SMIL";

export default function(props) {
  const title = "SVG Animation";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Slide in={true}>
      <Grid container>
        <Grid item>
          <Header title={title} created="03/10/2020" />
          <Paragraph>
            There are several ways to animate an SVG on the web.  One way is to
            use <ExternalLink href={SVG_ANIMATION_WITH_SMIL_URL}>SMIL</ExternalLink>,
            which seems to have a murky future at best.  So we are going to look
            today at CSS Animation, specifically with regards to React components.
          </Paragraph>
          <Gist id={"22dbf6b80c6b135bff63326fff649c57"} />
          <Tooltip title="Look at me go!">
            <React.Fragment><SVGTutorial /></React.Fragment>
          </Tooltip>
          <Paragraph>
            {`
              And there you have it!  From here we could get creative and animate
              almost any attribute.  Let's chain some animations to make this
              thing a little more complex.
            `}
          </Paragraph>
          <Gist id="fc92959149c3bf83d0972acdd4e1fbbb" />
          <Grid container justify="center">
            <Grid item>
              <SVGTutorial0 />
            </Grid>
          </Grid>
          <Paragraph>
            {`
              There we go.  This is a bit of a brittle way to animate things.
              if you look at the code I am using an update interval of 1750ms or
              so.  If your computer is slow enough.. that time might not have
              finished and your animation might look sligtly different.  I'd
              suggest finding a framework!
            `}
          </Paragraph>
          <Paragraph>
            {`
              So to recap:  Animation is fun and so is SVG.  There are many ways
              to apply these two together and we have looked at one.  Get out
              there and get animating.
            `}
          </Paragraph>
          <Grid container justify="center">
            <Grid item>
              <BackButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
