import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { BackButton, ExternalLink } from "../../../components/common";
import Header from "./Header"
import Paragraph from "./Paragraph"

export default function(props) {
  const title = "Huion H610 Pro V2 Review";
  return (
    <Container maxWidth="md">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="08/10/2021" />
            <Paragraph>
            {`
              With my recent dive back into
            `}
            <ExternalLink href="/post/getting-going-with-godot">game development</ExternalLink>
            {`
              I thought it might be a good time to dust off my graphics tablet.
              Several years ago (April 2017 to be specific), I had bought the Huion H610 Pro
              with high aspirations of mastering digital vector graphics.  At the time I
              am not sure I had the illustrator skills to properly utilize this
              peripheal.  Several years later and plenty of experience with illuistrator
              I was ready to tackle this thing once again.  Unfortunately, once
              I had dug out my almost 5 year old tablet, it wasn't working.  I quickly
              identified the pen battery as the issue, but decided to just upgrade.
            `}
            </Paragraph>
            <Paragraph>
            {`
              5 years later and we now have the second version of this tablet.  Following
              is the unboxing and review.  First lets show what we got in the box:
            `}
            </Paragraph>
            <Grid container justify="center">
              <ExternalLink>
                <img alt="huion h610 pro v2 unboxing" src="/blog/HuionH610ProV2-2.jpg" width="500" />
              </ExternalLink>
            </Grid>
            <Grid container justify="center">
              <Typography variant="h4">In the Box</Typography>
              <ul>
                <li>Tablet</li>
                <li>Pen & Holder</li>
                <li>Mini USB to USB cable</li>
                <li>USB to USB-C adapter, USB to Mini USB adapter</li>
                <li>instructions and other waste of paper</li>
              </ul>
            </Grid>
            <Paragraph>
            {`
              A few things I noticed immediately during the unboxing was the missing
              bag and glove.  However, the upgrade with this version is the stylus not
              needing to be charged.  Using version 1 I often felt restricted due to the low battery life.
              This version still requires the tablet
              itself to be plugged in.
            `}
            </Paragraph>
            <Typography variant="h4">Driver</Typography>
            <Paragraph>
            {`
              Pretty much everything you need to get rolling is at this download
            `}
            <ExternalLink href="https://www.huion.com/download/">link</ExternalLink>
            {`
              to the drivers.  Once you have installed that just plug in the
              tablet to your operating system and you should be good to go.
            `}
            </Paragraph>
            <Typography variant="h4">Overall Review</Typography>
            <Paragraph>
            {`
              👍👍 This version seems immediately more responsive than the v1 version.
              At this price point you can't beat this quality.
            `}
            </Paragraph>
            <Typography variant="h4">Competitors</Typography>
            <Paragraph>
            {`
              It seems to main competitor brand to this HUION tablet would be
              the WACOM
            `}
            {`
              but it is exactly twice the price.
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
