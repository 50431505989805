import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import { BackButton } from "../../../../components/common";
import Gist from "react-gist"
import Header from "../Header"
import Paragraph from "../Paragraph"

export default function(props) {
  const title = "Rotational Cipher";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Container maxWidth="sm">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="06/15/2021" />
            <Paragraph>
            {`
              I wanted to share my source code for a problem that I was working
              on recently.  It is called Rotational Cipher and it goes as follows
            `}
            </Paragraph>
            <img alt="rotational-cipher" width="500" src="/blog/RotationalCipher.png" />
            <Paragraph>
            {`
              I wanted to share my solution in Javascript:
            `}
            </Paragraph>
            <Gist id="5f5c7593e4580ec4f76b5b940eea2fd0" />
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
