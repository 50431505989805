import React from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { BackButton, ExternalLink } from "../../../components/common";
import Paragraph from "./Paragraph"

export default function(props) {
  const title = "Travel Guide Costa Rica 2021";
  return (
    <Container maxWidth="sm">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Typography variant="h2">
              <img alt="atenos peak" width="50" src="/blog/palm-colour.svg" />
              {title}
              <img alt="atenos peak" width="50" src="/blog/palm-colour.svg" />
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Posted on 07/28/2021
            </Typography>
            <Divider style={{marginBottom: '20px'}}/>
            <Paragraph>
            {`
              Recently I challenged myself to travel abroad.  A few of my criteria
              going into this were to A) get a visa stamp on my passport B) be at
              a beach with clear water, and C) ride a moped to all of my destinations.
            `}
            </Paragraph>
            <Paragraph>
            {`
              After not enough research I decided upon Central America, but more
              specifically Costa Rica.  A co-worker of mine had recently gone and
              return since things opened up from the covid vaccine, that combined
              with other anecdotal tales of success -- I was sold.
            `}
            </Paragraph>
            <Paragraph>
            {`
              Once I had finally arrived I had realized that I had missed on the
              clear water.  I was on the Pacific-side coast and the water was
              murkey like any other east coast beach I have been used to.  This
              was a bit of a let-down, but I was having so much fun -- I could care
              less.  That along with the fact that the water was bath-water warm my
              entire trip.  Having to go to another beach in the Carribean doesn't
              seem like the worst punishment either.
            `}
            </Paragraph>
            <Typography variant="h4">Hotel</Typography>
            <Paragraph>
            {`
              Since this was my first trip to Costa Rica I decided to stay in
              the capital city of San José.  The hotel that I stayed at was the
              Hotel Barcélo near Uruca.  This hotel was very nice and offered
              everything a traveler would need.  However, it was not as warm in
              late july in the city, so the pool was not really that intriguing.
              They had a fantastic work-out room and a hot tub in there.  The one
              issue I ran into was the walk-ability to the rest of the city from
              this location.  Once I looked how to get to a restaurant across the
              interstate, and it suggested I just walk along the interstate to get
              there.  With the crazy traffic and rain, I opted to not go this route.
              There were plenty of nice views close to the city within driving distance too.
              This view was only 20 minutes away from my hotel:
            `}
            </Paragraph>
            <Grid container justify="center">
              <ExternalLink href="/blog/san-jose-costa-rica.jpg">
                <img alt="mt. guichi" width="500" src="/blog/san-jose-costa-rica.jpg" />
              </ExternalLink>
            </Grid>
            <Paragraph>
            {`
              As the rains continued to pour down I decided that I would just get
              out of the city and rent an additional hotel in the local beach town
              of
            `}
            <ExternalLink href="https://en.wikipedia.org/wiki/Jac%C3%B3,_Costa_Rica">Jacó</ExternalLink>
            {`.  This turned out to be a great idea because the weather was
              much more pleasing on the coast.  I was able to get several sunny
              mornings of surfing in before the afternoon rains started.  When
              I do this again, I might just opt to stay at the beach for my
              entire stay.  I did not find anything that exciting in the city, but
              there was also a 9PM curfew due to covid.
            `}
            </Paragraph>
            <Typography variant="h4">Flight</Typography>
            <Paragraph>
            {`
              The flights were mainly a non-issue.  I will just say prepare to
              do a lot of waiting and standing in line.  I knew there would be some
              sort of rigamarole to get into another country, but it isn't really
              spelled out in any way.  One thing you needed to get into Costa
              Rica was your pasa de salud.  This ensured you had your travel
              insurance that was up to code with the covid protocols of costa rica.
              Once I arrived from MIA to SJO I had to go through customs and present
              this same document to immigration.
            `}
            </Paragraph>
            <Paragraph>
            {`
              On the way back it was more of the same, except since MIA was my
              first stop I had to go through customs there.  This ment retrieving
              my checked bag, and then re-checking it with the airline.  This
              process was not as bad as it sounds, and was only a short line.
            `}
            </Paragraph>
            <Typography variant="h4">Rental Car</Typography>
            <Paragraph>
            {`
              One great thing about Costa Rica is the ability to use your US
              state drivers license while there.  I found out there is something
              called an international drivers license, but it is not required
              there.  Now that that was settled, it was time to hit the road!
            `}
            </Paragraph>
            <Paragraph>
            {`
              It may have been a different country, but the rental car companies
              were still up to their old tricks.  The price of the rental car was
              so low that I opted to rent a car for all 7 days I was in the country.
              I have had some bad experiences with damaging rental cars, plus
              the fact that I was about to drive in another country for the first
              time -- I opted for the premium insurance.  This _skyrocketed_ the
              cost of the rental car, and ended up costing more than the flight
              or either of my hotel stays.  I would suggest not renting a car for
              your entire stay, if at all.  The rental car was very handy when
              exploring the various national forests of the region, but this was
              also a bit dicey in the rainy season.  (Think steep dirt roads in rain.)
              Next time I visit I plan to just rent some sort of ATV in Jacó and pretend
              to be participating in the sequal of Mad Max.
            `}
            </Paragraph>
            <Paragraph>
            {`
              One thing that I didn't even know until a few days into my trip
              was what the native currency to costa rica was.  Eventually I was
              at a toll road on my way from San Jose to Jacó and paid with a 10$
              bill and was returned some colorful money.  There was also plenty
              of coins.  I quickly realied the local note with the colon or colones
              for plural.  My phone quickly told me that 1 dollar equaled about 600
              of these bad boys, but of course that exchange rate did not hold up
              when arguing over the price of the toll road.  The dollar is accepted
              along with your credit card almost all over Costa Rica, so don't worry
              about exchanging your currency.
            `}
            </Paragraph>
            <Paragraph>
            {`
              Costa Rica as a whole is wildly beautiful.  One day I decided to take the
              scenic route back to San Jose from Jacó (without tolls) and was greeted
              by this magnificient site.  There was even a cafe to take in the view at:
            `}
            </Paragraph>
            <Grid container justify="center">
              <ExternalLink href="/blog/atenas-costa-rica.jpg">
                <img alt="atenos peak" width="500" src="/blog/atenas-costa-rica.jpg" />
              </ExternalLink>
            </Grid>
            <Paragraph>
            {`
              One thing to note is that the dry season is from mid December to April.
              So when I went in late July it was raining almost every day.  Certain
              days were torrentials rains all day, while others were just some threating
              storms.  Overall I wouldn't let a little rain get in your way, especially
              if you want to go to the beach in Jacó to surf.  The mornings seemed to be
              clear and then storms would roll in.  It was a little bit dicey for hiking
              during the wet season, since some trails will be muddy -- and flooding
              seems common.  One morning I wanted to visit a national park about an
              hour a way, but would have had to travel almost 4 hours to get there
              due to flooding.
            `}
            </Paragraph>
            <Paragraph>
            {`
              A few things that I packed but did not really need for my trip
              were my water purifier and headlamp.  Although I don't recommend
              drinking the tap water, it is easy enough to get bottled water,
              soda, or beer almost anywhere.  I was curious if my water purifier
              would have worked and saved me a few dollars (collones ;D) but I figured
              I'd rather not take the risk on my vacation.  As for my headlamp, I might
              recommend bringing it along anyway, but it was not very necessary.  I
              didn't travel much after dark and came across no power-outtages.
              However, the booming thunder of the storms on my last day had me thinking
              that perhaps that is not always the case.
            `}
            </Paragraph>
            <Typography variant="h4">Things to Remember</Typography>
              <ul>
                <li>
                  <ExternalLink href="https://salud.go.cr/">
                    “Pase de Salud” or “Health Pass”
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="https://travel.state.gov/content/travel/en/passports.html">Passport</ExternalLink>
                </li>
                <li>
                  <ExternalLink href="https://apps.apple.com/us/app/google-translate/id414706506">
                    Google Translate (Optional)
                  </ExternalLink>
                </li>
              </ul>
            <Paragraph>
            {`
              Bienvenidos everyone!
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
