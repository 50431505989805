import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import { BackButton } from "../../../components/common";
import Header from "./Header"
import Paragraph from "./Paragraph"
import Gist from "react-gist"

export default function(props) {
  const title = "React Deployment using Github Actions";
  return (
    <Container maxWidth="md">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="07/30/2021" />
            <Paragraph>
            {`
              Today I spent some time getting up to speed with Github actions.
              This site is currently using Github for it's deployment and I wanted
              to share you the action I wrote today for deployment.
            `}
            </Paragraph>
            <Gist id="e65759e8ae8534c31ac4aec288ac7df6" />
            <Paragraph>
            {`
              I have added some clarifying comments to describe each step.
              This should work for any node based project you have.  In my setup
              the source destination is an ubuntu server running nginx.
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
