import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography"
import { BackButton, ExternalLink } from "../../../components/common";
import Header from "./Header"
import Paragraph from "./Paragraph"

export default function(props) {
  const title = "Getting Going With Godot";
  return (
    <Container maxWidth="md">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="08/9/2021" />
            <Paragraph>
            {`
              I am traditionally a web developer, but I have grown up playing
              video games -- and from time to time like to program them.  I had
              taken some time off years ago to do game development personally, yet
              nothing because of that time spent.  At the time, I had opted to
              try
            `}
            <ExternalLink href="https://unity.com/">Unity</ExternalLink>
            {`
              and overall had enjoyed the experience.  This was around 2012
              or so and at the time it was pretty ground breaking.  (Godot would
              not even come into existance until 2014, two years later.)  I had a lot
              to learn when it came to game development, but I was able to stumble
              through.  I sold one unit, to someone who probably worked at Unity.
              It felt great and heart-breaking at the same time.  It was one thing
              to create a game, but it was another to sell units.  In 2020 I had
              the good fortunate to have time to do a bit of game develoment once
              again.  This time though, there was a new belle at the ball named
            `}
            <ExternalLink href="https://godotengine.org/">Godot</ExternalLink>
            {`.  This engine was completely open source and didn't require any
            sort of splash screen for free apps -- so sign me up!`}
            </Paragraph>
            <Typography variant="h4">Download</Typography>
            <Paragraph>
            {`
              Another great part of Godot, in addition to being open source,
              is that it is cross platform.  There is support in all of the
              major operating systems.  You can download the latest
            `}
            <ExternalLink href="https://godotengine.org/download">here</ExternalLink>
            {`.`}
            </Paragraph>
            <Typography variant="h4">Getting Started</Typography>
            <Paragraph>
            {`
              A game engine is a very complex thing, so first thing is to determine
              what type of game you want to make.  There is an almost endless list
              of
            `}
            <ExternalLink href="https://en.wikipedia.org/wiki/List_of_video_game_genres">video game genres</ExternalLink>
            {`
              , so pick the one you like the most.  I always enjoy 2D games, so I went
              to the asset store and downloaded a
            `}
            <ExternalLink href="https://godotengine.org/asset-library/asset/112">2D Isomorphic Example</ExternalLink>
            {` in order to get going.`}
            </Paragraph>
            <Grid container justify="center">
              <ExternalLink href="https://raw.githubusercontent.com/godotengine/godot-demo-projects/3.2-57baf0a/2d/isometric/screenshots/isometric.png">
                <img alt="screenshot 1" src="https://raw.githubusercontent.com/godotengine/godot-demo-projects/3.2-57baf0a/2d/isometric/screenshots/isometric.png" width="500" />
              </ExternalLink>
            </Grid>
            <Paragraph>
            {`
              As you can see we now have something running out of the box to start with.
              The idea of attaching scripts to nodes is _very_ familiar from Unity,
              so I was able to pick up most of the concepts almost instantly.  If you
              have tried Unity and want to try another game engine, I highly suggest
              giving Godot a try!
            `}
            </Paragraph>
            <Paragraph>
            {`
              After using the ideas from the files in the asset library I was
              able to fairly quickly come up with a 2D game that was ready to start
              coding and playing upon:
            `}
            </Paragraph>
            <Grid container justify="center">
              <ExternalLink href="/blog/GodotScreenshot1.png">
                <img alt="screenshot 1" src="/blog/GodotScreenshot1.png" width="500" />
              </ExternalLink>
            </Grid>
            <Paragraph>
            {`
              I will follow up with some of the lessons I have learned in the coming
              months, so look out for the updates!
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
