import React from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import Header from "../Header"
import Paragraph from "../Paragraph"

export default function(props) {
  const title = "Mining Ethereum (ETH) in 2021";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Container maxWidth="sm">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="03/31/2021" />
            <Paragraph>
            {`
              First thing is first! Lets get mining! Thats what crypto people
               do! Right?! Lets get to it.
            `}
            </Paragraph>
            {/*
              <Paragraph>
                {`
                  First off, if you beleive in crypto-currency this much at this
                   point I would start at by just buying it. I personally use
                   Coinbase (See post on
                `}
                <ExternalLink href="coinbasing-like-a-pro">Coinbasing like a pro</ExternalLink>
                {`
                  )
                `}
              </Paragraph>
            */}
            <Paragraph>
              {`
                First off, if you believe in crypto-currency this much at this
                 point I would start at by just buying it.  I suggest coinbase pro.
              `}
            </Paragraph>
            <Grid container justify="flex-start">
              <Grid item>
                <Typography variant="subtitle2">Remember: If you want to mine Ethereum you must remember to have at least
                {" "}<em>4GB</em> of vram!</Typography>
              </Grid>
            </Grid>
            <Grid container justify="flex-start">
              <Grid item>
                <Typography variant="h3">Ethereum Wallet</Typography>
              </Grid>
            </Grid>
            <Paragraph>
              {`
                First you will need a wallet to put your payout into.  I have been
                using Metamask as a way to store my ethereum.  You can download
                it
              `}
              <ExternalLink href="https://metamask.io/">here</ExternalLink>
              {`.`}
            </Paragraph>
            <Paragraph>
              The most important step in setting up your wallet on metamask is to
              make sure to write your security phrase down on pen(cil) and paper.
              If you save it on your computer then it can be compromised should
              your computer ever to be hacked.  One over the top step that I have
              recently done is to get a fire safe lockbox to put this security code
              in.  In case of fire, you don't want to lose all of your ETH.
            </Paragraph>
            <Grid container justify="flex-start">
              <Grid item>
                <Typography variant="h3">Mining program</Typography>
              </Grid>
            </Grid>
            <Paragraph>
              {`
                Then you need to find a mining program.  I personally
                use lolminer and find it quite simple.
                You can download the app
              `}
              <ExternalLink href="https://github.com/Lolliedieb/lolMiner-releases/releases">here</ExternalLink>
              {`.
                If you on windows choose the Win64 version and Lin64 for linux.

                Next you will want to find the the mine_eth.bat/sh file (depending on windows or linux).
              `}
            </Paragraph>
            <img alt="find mine eth" width="500" src="/blog/FindMineEth.png" />
            <Paragraph>
              {`
                Next make sure to update the wallet with your ETH wallet address.
                and you will need to pick a pool to mine to.  Nanopool has been fine for me
                but there are plenty of others.
              `}
            </Paragraph>
            <img alt="edit mine eth file" width="500" src="/blog/EditMineEth.png" />
            <Paragraph>
              {`
                Now once you start mining for a little while you can start to
                monitor your results like Mh/s (Megahash per second).  If you
                decided to use Nanopool as your Ethereum pool then you can visit
                your account page and see your progress.  Here is a screenshot of
                my account page where you can see my hash rates.
              `}
            </Paragraph>
            <img alt="pool account" width="500" src="/blog/poolaccount.PNG" />
            <Paragraph>
              {`
                As you can see -- the minimum payout for nanopool is .2 ETH.  So
                this is one reason you might choose another pool for mining.
              `}
            </Paragraph>
            <Paragraph>
              {`
                There we go!  We did it.  We finally put that GPU hardware that we
                bought for hundreds of dollars to work full time.  Remember that
                mining all day may reduce the longevity of your card.  However,
                hopefully we will have mined enough Ethereum to buy a new card
                and then some.  Enjoy!
              `}
            </Paragraph>
            <Typography variant="h5">References</Typography>
            <Divider />
            <div>
              - <ExternalLink href="https://www.youtube.com/watch?v=xqny5SSFRTo">
                Son of a Tech guide
                </ExternalLink>
            </div>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
