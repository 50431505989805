import React from "react"

const brain = `
  13,67,
  9,82,
  16,97,
  23,118,
  47,132,
  68, 129,
  91,132,
  107, 111,
  121, 96,
  127, 81,
  120, 64,
  114, 40,
  101, 9,
  91, 1,
  68, 7,
  47, 1,
  23, 29,
  13, 49,
  13, 67
 `;
const hive = `
 1,52
 1,80
 23,93
 23,118,
 47,132,
 69,119,
 91,132,
 114,118,
 114,93,
 136,80,
 136,52,
 114,40,
 114,15,
 90,1,
 69, 14,
 46, 1,
 23,15,
 22, 39,
 0, 52,`;
export default function () {
  const animationRef = React.useRef(null)
  const animation2Ref = React.useRef(null)
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
    height="50px" width="50px"
    viewBox="0 0 200 100"
    onClick={()=>{
      animationRef.current.beginElement()
      animation2Ref.current.beginElement()
    }}
    enableBackground="new 0 0 511 511">
      <polygon
        strokeWidth={5}
        stroke="black"
        fill="none"
         points={hive}
      >
        <animate ref={animationRef} attributeName="points" dur="5000ms"
        to={brain} repeatCount="indefinite"/>
      </polygon>
    </svg>

  )
}
