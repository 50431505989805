import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Blog, Post } from './components';
import {
  AsGoodAPlace,
  CoinBasingLikeAPro,
  CreateEthToken,
  CryptoCurrency101,
  DockerAndPostgres,
  GettingGoingWithGodot,
  HuionH610ProV2,
  FullStackApp,
  MiningEth2021,
  MonsterEye,
  PassiveIncomeSoftwareEngineer,
  ReactDeploymentGithubActions,
  RotationalCipher,
  SVGAnimation,
  SVGAnimationSMIL,
  TravelGuideCR2021,
} from './components/Post/components';

export default (props) => {
  return (
    <Switch>
      <Route
        component={Blog}
        exact
        path="/"
      />
      <Route
        component={CreateEthToken}
        exact
        path="/post/create-eth-token"
      />
      <Route
        component={FullStackApp}
        exact
        path="/post/full-stack-app"
      />
      <Route
        component={AsGoodAPlace}
        exact
        path="/post/as-good-a-place"
      />
      <Route
        component={SVGAnimation}
        exact
        path="/post/svg-animation"
      />
      <Route
        component={SVGAnimationSMIL}
        exact
        path="/post/svg-animation-smil"
      />
      <Route
        component={MonsterEye}
        exact
        path="/post/create-monster-eye-svg"
      />
      <Route
        component={DockerAndPostgres}
        exact
        path="/post/docker-and-postgres"
      />
      <Route
        component={CryptoCurrency101}
        exact
        path="/post/crypto-currency-101"
      />
      <Route
        component={CoinBasingLikeAPro}
        exact
        path="/post/coinbasing-like-a-pro"
      />
      <Route
        component={GettingGoingWithGodot}
        exact
        path="/post/getting-going-with-godot"
      />
      <Route
        component={HuionH610ProV2}
        exact
        path="/post/huion-h610-pro-v2-review"
      />
      <Route
        component={MiningEth2021}
        exact
        path="/post/mining-eth-2021"
      />
      <Route
        component={RotationalCipher}
        exact
        path="/post/rotational-cipher"
      />
      <Route
        component={TravelGuideCR2021}
        exact
        path="/post/travel-guide-costa-rica-2021"
      />
      <Route
        component={ReactDeploymentGithubActions}
        exact
        path="/post/react-deployment-github-actions"
      />
      <Route
        component={PassiveIncomeSoftwareEngineer}
        exact
        path="/post/passive-income-software-engineer"
      />
      <Route
        component={Post}
        exact
        path="/post/:slug"
      />
    </Switch>
  );
};
