import React from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
export default function (props) {
  const {title, created} = props;
  return (
    <React.Fragment>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="subtitle2" gutterBottom>
        Posted on {created}
      </Typography>
      <Divider style={{marginBottom: '20px'}}/>
    </React.Fragment>
  )
}
