import React from "react";

const brain = `
  13,67,
  9,82,
  16,97,
  23,118,
  47,132,
  68, 129,
  91,132,
  107, 111,
  121, 96,
  127, 81,
  120, 64,
  114, 40,
  101, 9,
  91, 1,
  68, 7,
  47, 1,
  23, 29,
  13, 49,
  13, 67
 `;
const hive = `
 1,52
 1,80
 23,93
 23,118,
 47,132,
 69,119,
 91,132,
 114,118,
 114,93,
 136,80,
 136,52,
 114,40,
 114,15,
 90,1,
 69, 14,
 46, 1,
 23,15,
 22, 39,
 0, 52,`;

const brainInternal = `
   42,52,
   45,55,
   51,52,
   60,54,
   64,64,
   66,94,
   60,122,
   69,123,
   66,112,
   68,79,
   74,82,
   85,82,
   92,81,
   87,79,
   72,77,
   68,67,
   68,37,
   80,51,
   85,52,
   95,46,
   92,41,
   89,43
   83,46,
   68,27,
   68,10,
   64,16,
   64,52,
   56,48,
   47,47,
   42,52,
`;
const hiveInteral = `
   24,43,
   22,47,
   42,58,
   42,70,
   42,81,
   22,93,
   24,96,
   44,84,
   54,90,
   64,96,
   64,119,
   68,119,
   68,96,
   78,90,
   88,84,
   108,96,
   110,93,
   90,81,
   90,75,
   90,58,
   110,47,
   108,43,
   88,55,
   68,43,
   68,20,
   64,20,
   64,43,
   54,49,
   44,55,
   24,43,
  `;
export default function() {
  const animationRef = React.useRef(null);
  const animation2Ref = React.useRef(null);
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height="100px"
      width="100px"
      viewBox="0 0 200 100"
      onClick={() => {
        animationRef.current.beginElement();
        animation2Ref.current.beginElement();
      }}
      enableBackground="new 0 0 511 511"
    >
      <polygon strokeWidth={5} stroke="black" fill="none" points={hive}>
        <animate
          ref={animationRef}
          attributeName="points"
          dur="5000ms"
          from={hive}
          fill="freeze"
          to={brain}
          repeatCount={1}
        />
      </polygon>

      <polygon strokeWidth={5} stroke="black" fill="none" points={hiveInteral}>
        <animate
          ref={animation2Ref}
          attributeName="points"
          dur="5000ms"
          from={hiveInteral}
          fill="freeze"
          to={brainInternal}
          repeatCount={1}
        />
      </polygon>
    </svg>
  );
}
