import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import Gist from "react-gist"
import Header from "../Header";
import Paragraph from "../Paragraph";
const HREF1 = "https://create-react-app.dev/docs/getting-started/";
const HREF2 = "https://docs.docker.com/compose/compose-file/#compose-and-docker-compatibility-matrix";
export default function(props) {
  const title = "Full Stack App";
  return (
    <Slide in={true}>
      <Grid container>
        <Grid item>
          <Header title={title} created={"02/17/2020"} />
          <Paragraph>
          {`
            Full Stack Developing is the hot industry buzz word these days.  What is
            a full stack developer?  Well they should be responsible for creating a
            dynamic web application with persistent storage that is secure,
            scalable, and does the user no harm.
          `}
          </Paragraph>
          <Paragraph>
            <Typography variant="body1" paragraph>
              {`
                I wanted to run through a recent app that I created.  To start let's break
                things down as a backend and frontend resource.  This way our frontend
                React code does not co-mingle in with the backend Javascript code unless
                specifically importing it.  We will be using `}
                <ExternalLink href={HREF1}>create-react-app</ExternalLink>
                {` to get going quickly.  The nice thing about this framework is
                that it has the ability to deploy immediately.  (Other great
                things too.. like abstracting babel.  Read their docs!)
              `}
              <ExternalLink href={HREF2}>here</ExternalLink>
              {`.`}
            </Typography>
            <Typography variant="body1" paragraph>
              {`
                That takes care of the frontend portion of the code.  Now for serving up
                this code we are going to enter another part of the full stack realm --
                 the webserver.  For this part we will be using nginx.  Here is an example
                 configuration: ( This file will typically live somewhere like
                   /etc/nginx/sites-available )
              `}
            </Typography>
          </Paragraph>
          <Gist id="29c904971e728433b20b6ddab93c5028" />
          <Paragraph>
            <Typography variant="body1" paragraph>
            {`
              Now we just run yarn build and put the build directory into the
              location we set as root.  Whalla.  We have our frontend.

              This takes care of the web portion of the stack.  A modern web app should
              be able to function on its own at this point.  If you are calling data
              api services, you should display your error states.
            `}
            </Typography>
            <Typography variant="body1" paragraph>
            {`
            Now on to get this backend api up and running.  In order to have synergy
            with the frontend we are going to use javascript for the backend, however,
            you could do this step in any language you choose.  We will be using node
            and expressjs for the backend.  This will take care of the http layer of
            the stack.
            `}
            </Typography>
            <Typography variant="body1" paragraph>
            {`
            Now we will need to choose a persistent storage for the backend to keep
            that data in.  I have come to like Postgres as a database, but there are
            so many choices out there.  Also this will depend at the task at hand.
            For most CRUD applications it makes sense to use a RDBS.
            `}
            </Typography>
            <Typography variant="body1" paragraph>
            {`
            Now that we have our frontend, API, DB, HTTP, layers ready.. it is time
            to add the domain, possibly load balance, and be ready to scale.
            `}
            </Typography>
          </Paragraph>
          <BackButton />
        </Grid>
      </Grid>
    </Slide>
  );
}
