import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
export default function (props) {
  const {children} = props;
  return (
    <Paper>
      <Typography
        variant="body1"
        style={{margin: '10px 20px', padding: '10px'}}
        paragraph>
        {children}
      </Typography>
    </Paper>
  )
}
