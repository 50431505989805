import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { BackButton, ExternalLink } from "../../../components/common";
import Header from "./Header"
import Paragraph from "./Paragraph"

export default function(props) {
  const title = "Passive Income as a Software Engineer 2021";
  return (
    <Container maxWidth="md">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="08/1/2021" />
            <Paragraph>
            {`
              Sometimes you find yourself with some extra time on your hands that
              you would prefer not waste.  If you are like me and you are looking for a way to passively
              generate some extra income from your work -- then I have some ideas.
              Here is my list so far:
            `}
            </Paragraph>
            <Typography variant="h4">Invest</Typography>
            <Paragraph>
            {`
              This was certainly one that I wished I had started sooner, which
              is the whole point of investing I suppose.  Make sure to pay
              off all of your existing debt.  Then you should maximize your
              401K contributions to the yearly maximum (if possible).  After
              that you can make a yearly contribution of 6k to your personal IRA.
            `}
            </Paragraph>
            <Paragraph>
            {`
              After all of that, if you still have more money to invest -- then
              you should invest in a mutual fund.  You can usually find the
              last years return investment online for each of the funds.  You
              should choose a high or low risk mutual fund depending on market
              conditions.
            `}
            </Paragraph>
            <Paragraph>
            {`
              I also advise that you keep at least 10% of your portfolio as
              cryptocurrency.  You can always earn 10$ on Coinbase by using
              my invite link
            `}
            <ExternalLink href="https://www.coinbase.com/join/higdon_pp">here</ExternalLink>
            {`.`}
            </Paragraph>
            <Typography variant="h4">Blog</Typography>
            <Paragraph>
            {`
              This one is pretty meta.  Basically, just start writing words on
              the internet to help others!  You are reading my attempt at this
              right now! If your info is helpful enough
              then you can run ads on these pages to help earn a passive income.
              This can be done using
            `}
            <ExternalLink href="https://www.google.com/adsense/start/">Google Adwords</ExternalLink>
            {`.`}
            </Paragraph>
            <Typography variant="h4">Sell Digital Assets</Typography>
            <Paragraph>
            {`
              This could be 3D effects, models or flat art packs.  There are several
              platforms that have this sort of thing built in.  One example of this is
              the
            `}
            <ExternalLink href="https://unity.com/">Unity</ExternalLink>
            {` `}
            <ExternalLink href="https://assetstore.unity.com/">Asset Store</ExternalLink>
            {`
              .  You can create parts of a game and sell them to other game makers.
              Likewise, there is another asset store for the
            `}
            <ExternalLink href="https://www.unrealengine.com/">Unreal Engine</ExternalLink>
            {`
              called the
            `}
            <ExternalLink href="https://www.unrealengine.com/marketplace/en-US/store">Unreal Marketplace</ExternalLink>
            {`.`}
            </Paragraph>
            <Typography variant="h4">Gig Work</Typography>
            <Paragraph>
            {`
              I never had great results with this one, but you can use sites like
            `}
            <ExternalLink href="https://www.upwork.com/">Upwork</ExternalLink>
            {`
               to try to pick up some software side gigs.  You can also use
               the tried and true method of
            `}
            <ExternalLink href="https://www.craigslist.org">Craigslist</ExternalLink>
            {`
              .  As always -- anything goes on craigslist!
            `}
            </Paragraph>
            <Typography variant="h4">Stream</Typography>
            <Paragraph>
            {`
              Streaming is all the rage these days and for good reason.  You can
              generate a bigger income from an internet following than you can
              from some low paying jobs.  Sites like
            `}
            <ExternalLink href="https://www.twitch.tv/">Twitch</ExternalLink>
            {`
              are traditionally video-game centric, but recently years have brought
              pretty much any form of entertainment.  There are currently software
              engineers doing this just for fun, but also make some profit.  In addition
              you can gain additional following on platforms such as
            `}
            <ExternalLink href="https://www.youtube.com/">Youtube</ExternalLink>
            {`
              by uploading videos and streaming there as well.
            `}
            </Paragraph>
            <Typography variant="h4">Mine Crypto</Typography>
            <Paragraph>
            {`
              Assuming you have access to a powerful enough GPU you can also
              earn by mining on a blockchain. You can find my other post on how
              to set up mining for
            `}
            <ExternalLink href="https://hivemind.monster/post/mining-eth-2021">ethereum here</ExternalLink>
            {`
              . If you need a refresher on any of these crypto terms you can
              head over to my post explaining that
            `}
            <ExternalLink href="https://hivemind.monster/post/Crypto-Currency-101">here</ExternalLink>
            {`
              .
            `}
            </Paragraph>
            <Typography variant="h4">Affiliate Marketing</Typography>
            <Paragraph>
            {`
              This is another new popular form of generating some revenue.  With
              Affiliate Marketing you get a cut of the companies profits for
              new sales or signups.  For example, if you are a Twitch streamer --
              you can have links to the things you use to stream -- with affiliate links
              to say
            `}
            {` `}
            <ExternalLink href="https://affiliate-program.amazon.com/">Amazon</ExternalLink>
            {` or even `}
            <ExternalLink href="https://www.zappos.com/associates-program">Zappos</ExternalLink>
            {`
              to buy their products, and make a buck. They even provide you with code to put
              on your site.  For example if you wanted to buy the keyboard I am using
              to write this blog post you can find it below:  (Note that it helps me type
              faster and longer!)
            `}
            </Paragraph>
            <ExternalLink href="https://www.amazon.com/gp/product/B07K1SMRGS/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07K1SMRGS&linkCode=as2&tag=juicemangoo-20&linkId=11a3c890582b7d7d7c0dd17ae29d7af6">Kinesis Advantage2 Quiet LF Ergonomic Keyboard (KB600LFQ)</ExternalLink>
            <Paragraph>
            {`This is just one way to capitalize
              on some extra income, but this method can be used across the board.  Heck,
              I am doing it above in this article.  Many Instagram influencers
              make very good buck doing this using products that they may
              already use in their every day.  Think of it as a nice jump point
              between being a celebrity hocking a product and the normal person.
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
