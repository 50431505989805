import React from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import Header from "../Header"
import Paragraph from "../Paragraph"

export default function(props) {
  const title = "Coinbasing like a pro";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Container maxWidth="sm">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="04/02/2021" />
            <Paragraph>
            {`
              First thing you need when buying and selling crytpo-currency is
              an exchange. When I decided to take the plunge into Crypto-currency I decided
              I would try Coinbase.  This had a lot to do with the fact that
              Coinbase would be seeking an IPO soon.  When you first sign up it
              asks whether you want a pro account or not.  It doesn't really
              matter at this point since you can use each of them interchangeably.
              The normal coinbase site has great graphs and allows for staking
              your coins.  Pro seems to offer more up to date market prices and
              better fees.  In general, I would recommend everyone does their
              trading in Coinbase Pro.  For day to day staking and pretty graphs
              you can use normal Coinbase.
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <Typography variant="h3">Staking Rewards</Typography>
              </Grid>
            </Grid>
            <Paragraph>
            {`
              Coinbase allows you to stake your balance you are investing.  So
              by investing in a coin on their site you automatically receive rewards.
              It appears that this functionality may only be available on normal coinbase
              and not on coinbase pro.
            `}
            </Paragraph>
            <Grid container justify="center">
              <img alt="staking rewards" width="400" src="/blog/stakingrewards.png" />
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Typography variant="h3">Fees</Typography>
              </Grid>
            </Grid>
            <Paragraph>
            {`
              You will see in your coinbase pro account that the fees associated
               with your trades are on a scale of how much you have spent for the
               last 30 days.  The more you spend the less the fees.
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <Typography variant="h3">Market, Stop and Limit</Typography>
              </Grid>
            </Grid>
            <Paragraph>
            {`
              When purchasing crypto-currency you will have the option to buy, or
              sell.  Each one of these orders can be put in as Market, Stop,
              or Limit.  Market means the current price of the currency on the
              market.  So if ETH is trading for $2,400 on the exchange -- then
              that is the price you will buy it at.  If you set a limit of $2,200
              and the price of ETH never falls below $2,201 then your buy will
              never execute.  This can be used effectively to buy dips in a price.
              If your limit is higher than market, then your buy will immediately
              execute.
            `}
            </Paragraph>
            <Paragraph>
            {`
              If you perform a Stop Buy of the same $2400 ETH you can set a STOP
              of 2500$ and if ETH ever hits a price of 2500$ then your buy order
              would be executed.  You may be asking yourself "Why would I ever want
              to do that? I could have just bought for 2400$.. why wait till it is
              more expensive." The answer is you probably won't ever use this option
              when trading, and is used to cover shorting of a commodity.
            `}
            </Paragraph>
            <Paragraph>
            {`
              So far we have only covered the BUY trades.  I'd say that a Market
              sell is pretty self-explanatory at this point.  So let us move on
              to the Limit Sell.  If I had just bought that ETH for $2400 then
              I might want to set a sell limit of $2500 in order to profit of
              100$.  Now imagine I wanted to do the opposite and sell at $2300
              to make sure I get out before a dip.  This is where you would want
              to execute a Sell Stop.
            `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <Typography variant="h3">Coinbase Pro API</Typography>
              </Grid>
            </Grid>
            <Paragraph>
            {`
              Coinbase has a nice programatic API in case you would really like
              to step up your trading game.  You can find the API docs
            `}
            <ExternalLink href="https://developers.coinbase.com/">here.</ExternalLink>
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <Typography variant="h3">Competitors</Typography>
              </Grid>
            </Grid>
            <Paragraph>
            {`
              If you aren't happy with Coinbase there are a slew of competitors
              in the exchange space.
            `}
            </Paragraph>
            <Typography variant="h5">Exchanges</Typography>
            <Divider />
            <div>
              - <ExternalLink href="https://binance.com">
                Binance
                </ExternalLink>
            </div>
            <div>
              - <ExternalLink href="https://www.gemini.com">
                Gemini
                </ExternalLink>
            </div>
            <div>
              - <ExternalLink href="https://www.kraken.com">
                Kraken
                </ExternalLink>
            </div>
            <Paragraph>
            {`
              If you want to earn 10$ of BTC after you buy 100$ in
              crypto-currency you can use this
            `}
            <ExternalLink href="https://www.coinbase.com/join/higdon_pp">link</ExternalLink>
            {` to join coinbase as my friend.`}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
