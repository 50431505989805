import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton } from "../../../../components/common";
import Header from "../Header"
import Paragraph from "../Paragraph"

export default function(props) {
  const title = "Crypto Currency 101";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Container maxWidth="sm">
      <Slide in={true}>
        <Grid container>
          <Grid item>
            <Header title={title} created="03/24/2021" />
            <Paragraph>
            {`
              I thought it would be a good idea to start researching the crypto
               market after the recent increase in Bitcoin (BTC). I knew that
               there was a competitor in Ethereum and a wide array or other
               coins available. I work as a Software Engineer so the idea of
               Block-Chain was not that foreign to me, but I had not spent any
               time researching it.  I knew that BTC was the leader of the game
               but Ethereum (ETH) was a close competitor.  I also had heard good
               things about Cardano (ADA), and it being a pioneer in the Proof
               of Staking methodology of mining.  So lets start off with this, the
               method of generating new tokens.
            `}
            </Paragraph>
            <Grid container justify="flex-start">
              <Grid item>
                <Typography variant="h3">Proof of Work(PoW) aka "mining"</Typography>
              </Grid>
            </Grid>
            <Paragraph>
              {`
                Proof of work is the original system used by Bitcoin and Ethereum.
                The general gist of this is that in order to create new coins, you
                must perform intense math which requires a certain amount of energy (cost)
                to create.  This is why GPUs are used in this system -- they are built
                for complex calculations.  In general, this methodology uses a lot of
                electricity.  This is why the greastest minds have been coming up with
                a way of creating new tokens without an intense need for energy consumption.
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <Typography variant="h3">
                  Proof of Stake (PoS) aka Staking
                </Typography>
              </Grid>
            </Grid>
            <Paragraph>
              {`
                The idea here is that to generate new coins you "stake" some of
                your coins in order to receive a reward.  Imagine it this way...
                You have 5 apples.. and another person has 10 apples.  They would
                be twice as likely to be selected for the Apple Picking contest
                because they have twice as many Apples.  There is an upper limit
                however, so Daddy Warbucks can't bring his 5000 apples and
                instantly win.  Also it is still random.. so the fellow who brought
                his 5 apples still has a chance to win.
              `}
            </Paragraph>
            <Paragraph>
              {`
                A few of the coins that are currently using this system are Cardano,
                Cosmos, Algorand, and Tezos.  The future looks bright for PoS coins
                and even Ethereum is looking into switching over to this system.
              `}
            </Paragraph>
            <Grid container justify="center">
              <Grid item>
                <BackButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Container>
  );
}
