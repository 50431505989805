import React from 'react';
import {useHistory} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function () {
  const history = useHistory();
  return (
    <IconButton
      style={{marginBottom: '15px'}}
      onClick={()=>{
      history.push('/')
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  )
}
