import React from "react";
import Link from '@material-ui/core/Link';
export default function (props) {
  const {children, href} = props
  return (
    <Link
      rel="noopener"
      target="_blank"
      href={href}>
        {children}
    </Link>
  )
}
