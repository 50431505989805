import React from "react";

export default function () {
  const [cx, setCx] = React.useState(103.18)
  const [cy] = React.useState(149.39)
  const [isOut, setIsOut] = React.useState(false)
  const WIGGLE = 50
  React.useEffect(()=>{
    setTimeout(()=>{
      setCx(cx=> isOut?cx + WIGGLE : cx - WIGGLE)
      setIsOut(isOut=>!isOut)
    }, 1750)
    // return () => {
    //   var id = window.setTimeout(function() {}, 0);
    //   while (id--) {
    //       window.clearTimeout(id); // will do nothing if no timeout with id is present
    //   }
    // }
  }, [setCx, isOut])

  return (
    <svg
      style={{
        transition: 'all 2s'
      }}
      width="100px" height="100px" version="1.1" viewBox="0 0 210 297">
     <circle
       style={{
         transition: 'all 2s'
       }}
     cx={cx} cy={cy} r="30" fill="#008080" strokeWidth=".26458"/>
    </svg>
  )
}
