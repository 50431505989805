import React from "react";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import { BackButton, ExternalLink } from "../../../../components/common";
import {default as MonsterEyeIcon} from "../../../../components/Icons/MonsterEye";
import Gist from "react-gist"
import Header from "../Header"
import Paragraph from "../Paragraph"

const INKSCAPE_HREF = "https://inkscape.org/";
const INKSCAPE_TUTORIAL = "https://www.youtube.com/watch?v=8f011wdiW7g";

export default function(props) {
  const [showSource, setShowSource] = React.useState(false);
  const title = "Create Monster Eye SVG";
  React.useEffect(()=>{
    return () => {
      var id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
    }
  }, [])
  return (
    <Slide in={true}>
      <Grid container>
        <Grid item>
          <Header title={title} created="03/13/2020" />
          <Paragraph>
            {`
              Today we are going to create a bit more complex of an animation
              using SVG and React.  To create the assets for this tutorial I
              used
            `}
            <ExternalLink href={INKSCAPE_HREF}>Inkscape</ExternalLink>
            {`
              , it is a great tool.  I'd suggest starting with this
            `}
            <ExternalLink href={INKSCAPE_TUTORIAL}>video series</ExternalLink>
            {`
              .  Once you are familiar with the tool we are going to just use
              three shapes to create this final product.  We need one circle
              for the eye white, one elipsis for the pupil, and then two
              half-circles for the eye lids.
            `}
          </Paragraph>
          <Grid container justify="center">
            <Grid item>
              <video
                style={{paddingBottom:"20px"}}
                width="400"
                height="400"
               controls src="/blog/create-monster-eye-inkscape.webm" type="video/webm"/>
            </Grid>
          </Grid>

          <Paragraph>
            {`
              Now that we have our SVG file.. You can just open it in your editor.
              From there we are just going to translate it into a React component.
              We will use React Hooks to keep track of our state.
            `}
          </Paragraph>
          <Collapse in={showSource}>
            <Gist id="cd22ed175d4e3773b749cdf9f22bb9b2" />
          </Collapse>
          <Button onClick={()=>setShowSource(!showSource)}>
            {!showSource?"Show Source":"Hide Source"}
          </Button>
          <Paragraph>
            {`
              I'd say our final product is looking pretty spooky!  Go ahead and
              click on it.
            `}
          </Paragraph>
          <Grid container justify="center">
            <Tooltip title="Click me!">
              <Grid item>
                <MonsterEyeIcon />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <BackButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
