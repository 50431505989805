import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton } from "../../components/common";
import tileData from "../../tileData";
export default function(props) {
  const { match } = props;
  const post = tileData.find(
    tile => match && match.params && tile.slug === match.params.slug
  );
  if (!post) {
    return null;
  }
  return (
    <Slide in={post !== undefined}>
      <Grid container justify="center">
        <Grid item>
          <Typography variant="h2">{post.title}</Typography>
          <Typography variant="subtitle2">Posted on 03/09/2020</Typography>
          <ReactMarkdown
            source={post.text}
            escapeHtml={false}
          />

          <BackButton />
        </Grid>
      </Grid>
    </Slide>
  );
}
