import React from "react"

const brainInternal=`
  42,52,
  45,55,
  51,52,
  60,54,
  64,64,
  66,94,
  60,122,
  69,123,
  66,112,
  68,79,
  74,82,
  85,82,
  92,81,
  87,79,
  72,77,
  68,67,
  68,37,
  80,51,
  85,52,
  95,46,
  92,41,
  89,43
  83,46,
  68,27,
  68,10,
  64,16,
  64,52,
  56,48,
  47,47,
  42,52,
  `;
const hiveInteral = `
  24,43,
  22,47,
  42,58,
  42,70,
  42,81,
  22,93,
  24,96,
  44,84,
  54,90,
  64,96,
  64,119,
  68,119,
  68,96,
  78,90,
  88,84,
  108,96,
  110,93,
  90,81,
  90,75,
  90,58,
  110,47,
  108,43,
  88,55,
  68,43,
  68,20,
  64,20,
  64,43,
  54,49,
  44,55,
  24,43,
 `;
export default function () {
  const animation2Ref = React.useRef(null)
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
    height="150px" width="150px"
    viewBox="0 0 200 100"
    onClick={()=>{
      animation2Ref.current.beginElement()
    }}>

      <polygon
      strokeWidth={5}
      stroke="black"
      fill="none"
      points={brainInternal}
      >
        <animate
          fill="freeze"
          repeatCount={1}
          ref={animation2Ref}
          attributeName="points"
          dur="7500ms"
          to={hiveInteral}
        />
      </polygon>


    </svg>

  )
}
