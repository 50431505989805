import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import tileData from "../../tileData";
import HiveMind from "../Icons/HiveMind";
import { Search } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    minHeight: "500px",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    paddingBottom: 25,
    marginTop: 25,
    width: "275px"
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  },
  availTag: {
    margin: theme.spacing(0.2)
  },
  leftNav: {
    marginLeft: theme.spacing(1),
    width: "100%"
  }
}));

const AVAIL_TAGS = [
  { name: "React", icon: "icons/react-logo.svg" },
  { name: "Docker", icon: "icons/Moby-logo.png" },
  { name: "NodeJS", icon: "icons/nodejs.svg" },
  { name: "Postgres", icon: "icons/Postgresql_elephant.svg" },
  { name: "NGINX", icon: "icons/NGINX-logo.svg" },
  { name: "SVG", icon: "icons/SVG_logo.png" },
  { name: "Crypto-Currency", icon: "icons/crypto-currency.svg"},
  { name: "Travel", icon: "blog/palm-colour.svg"},
  { name: "Godot", icon: "blog/64px-Godot_icon.svg.png"},
  { name: "Product Review", icon: "blog/priceiswrong.svg"},
];

function Logo() {
  return (
    <Grid
      container
      alignItems="center"
      style={{ padding: 10 }}
      direction="column"
      justify="center"
    >
      <Grid item style={{ padding: 10 }}>
        <HiveMind />
      </Grid>
      <Grid item>
        <Typography>A few words about software</Typography>
      </Grid>
    </Grid>
  );
}

function Tags(props) {
  const classes = useStyles();
  const { tagFilters } = props;
  return (
    <div>
      <Typography variant="h6">Tags:</Typography>
      {AVAIL_TAGS.map(tag => {
        const selected = tagFilters.includes(tag.name);
        return (
          <Chip
            icon={tag.icon ? <img alt={tag.icon} src={tag.icon} /> : undefined}
            size="small"
            clickable
            onDelete={selected ? () => props.onDelete(tag.name) : undefined}
            onClick={props.onClick}
            className={classes.availTag}
            key={tag.name}
            variant="outlined"
            label={tag.name}
          />
        );
      })}
    </div>
  );
}

const StyledGridListTile = withStyles({
  tile: {
    borderRadius: 25,
  },
})(GridListTile);

export default function() {
  const [tagFilters, setTagFilters] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Fade in={true}>
        <Grid container justify="space-around">
          <Hidden xsDown>
            <Grid item xs={3}>
              <Grid
                container
                className={classes.leftNav}
                spacing={5}
              >
                <Grid item style={{ width: "100%" }}>
                  <Logo />
                </Grid>
                {/*
                <Grid item>
                  <Typography variant="h6">Useful Links:</Typography>
                  <div><Link>Latest Post</Link></div>
                  <div><Link>Random Post</Link></div>
                </Grid>
              */}
                <Grid item style={{ width: "100%" }}>
                  <Tags
                    tagFilters={tagFilters}
                    onDelete={name => {
                      setTagFilters(tagFilters.filter(t => t !== name));
                    }}
                    onClick={e => {
                      setTagFilters([...tagFilters, e.target.textContent]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={9}>
            <Hidden smUp>
              <Logo />
            </Hidden>
            <Search
              onChange={value => {
                setSearchTerm(value);
              }}
            />
            <Grid container direction="row" justify="center" spacing={5}>
              {tileData
                .filter(data => {
                  return data.tags.reduce((hasTag, dataTag) => {
                    if (hasTag) {
                      return hasTag;
                    }
                    return tagFilters.includes(dataTag);
                  }, tagFilters.length === 0);
                })
                .filter(data => {
                  return (
                    data &&
                    data.title
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase()) >= 0
                  );
                })
                .map((tile, idx) => (
                  <Grid item key={tile.img}>
                    <div
                    style={{ cursor: 'pointer'}}
                    onClick={() => history.push("/post/" + tile.slug)}>
                      <GridList
                        cols={1}
                        cellHeight={180}
                        className={classes.gridList}
                      >
                        <StyledGridListTile>
                          <img src={`blog/${tile.img}`} alt={tile.title} />
                          <GridListTileBar
                            title={tile.title}
                            subtitle={<span>posted on: {tile.created}</span>}
                          />
                        </StyledGridListTile>
                      </GridList>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
}
