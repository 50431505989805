import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { BackButton } from "../../../../components/common";
import Header from "../Header";

export default function(props) {
  const title = "As Good a Place";

  return (
    <Slide in={true}>
      <Grid container justify="center">
        <Grid item>
          <Header title={title} created="02/02/2020" />
          <Typography variant="body1" paragraph>
            Oooooooweeee Jerry, this is going to be fun.
          </Typography>
          <Typography variant="body1" paragraph>
            I had interest in writing a blog a long time ago.  Lets say circa 1995.
            I rolled up my sleeves and ran those magical django commands and before you
            know it.. I was up and rolling.
          </Typography>
          <Typography variant="body1" paragraph>
            After a few glances from a friend they
            noted how hostile I sounded.  I scoffed at the idea.. since I had been a fan
            of bileblog and that other guy.. the one with the cool blog.  I attempted to
            google him just now.  and it wasn't to be.  Nevertheless!  I eventually took
            their advice and made things a bit more professional.  At this point I
            really didn't know what else to do.  I had made the blog.. infamy was about
            roll in.. I guess.
          </Typography>
          <Typography variant="body1" paragraph>
            At the time I had not programmed much in my spare time, and didn't
            really have any idea on what was next.  Well, one good idea is to
            use google analytics to track what my current traffick was.
          </Typography>
          <Typography variant="body1" paragraph>
          </Typography>

          <Typography variant="body1" paragraph>
          </Typography>
          <BackButton />
        </Grid>
      </Grid>
    </Slide>
  );
}
